import { Lightning, Img } from "@lightningjs/sdk";
const baseImageUrl =
  process.env.APP_BACKEND_URL || `https://devportal.ok-television.com`;

export default class BannerItem extends Lightning.Component {
  static _template() {
    return {
      Icon: {
        w: 1920,
        h: 350,
      },
    };
  }

  _init() {}

  _active() {
    const url = baseImageUrl + this.field_imagen;
    this.patch({
      Icon: Img(url).cover(),
    });
  }

  static get marginRight() {
    return 0;
  }

  static get marginBottom() {
    return 0;
  }

  static get marginLeft() {
    return 0;
  }

  static get marginTop() {
    return 0;
  }

  static get height() {
    return 400;
  }

  static get width() {
    return 1920;
  }
}
