import { Colors, Lightning, Utils } from "@lightningjs/sdk";
import { focusedColor, primaryColor } from "../Lib/Common";
import { ProgressBar } from "@lightningjs/ui";

const defaultColor = Colors("#0D1216").get();
const focusColor = primaryColor;

export default class LoadingIndicator extends Lightning.Component {
  static _template() {
    return {
      alpha: 0,
      LoadingIndicator: {
        type: ProgressBar,
        h: 10,
        w: 400,
        backgroundColor: Colors("#fff").get(),
        progressColor: focusedColor,
      },
    };
  }
  
  set width(val){
      this.patch({
            LoadingIndicator:{
                  w: val
            }
      })
  }
  _init() {
    this.tag("LoadingIndicator")
      .animation({
        duration: 3,
        repeat: -1,
        stopMethod: "immediate",
        actions: [
          {
            t: "",
            p: "value",
            v: {
              0: 0,
              0.5: 50,
              0.8: 100,
            },
          },
        ],
      })
      .start();
  }
  hide() {
      this.setSmooth("alpha", 0);
  }
  show() {
      this.setSmooth("alpha", 1);
  }
}
