import { Language, Storage } from "@lightningjs/sdk";
import {
  getDevices,
  getIp,
  getOperators,
  removeDevice,
  saveDevice,
} from "../Services/Api/Routes/User";
import { errorColor } from "../Components/Alert";

export async function verifyDevices(operators, userId, page) {
  let operatorIndex = 0;
  let currentOperator = operators[operatorIndex];
  let maxDevices = getMaxDevices(currentOperator);

  let devicesResponse = await getDevices(userId, currentOperator.operador);
  let devices = devicesResponse.data;

  let maxDevicesReached = maxDevices <= devices.length;

  while (maxDevicesReached) {
    if (operators.length <= operatorIndex + 1) {
      //max devices reached in all operators.
      const config = Storage.get("basic_config");
      const alertDuration = config.find((x) => x.key === "alert_timeout").value;
    
      page.tag("Alert").message = Language.translate("security_max_devices_reached");
      page.tag("Alert").type = errorColor;
      page.tag("Alert").showAlert(alertDuration);

      return false;
    }
    console.log("operatorIndex " + operatorIndex);
    console.log("currentOperator " + currentOperator);
    currentOperator = operators[++operatorIndex];
    console.log("currentOperator " + currentOperator);
    let maxDevices = getMaxDevices(currentOperator);

    devicesResponse = await getDevices(userId, currentOperator.operador);
    devices = devicesResponse.data;

    maxDevicesReached = maxDevices <= devices.length;
  }

  //save the current operator
  Storage.set("current_operator", currentOperator);

  const dataObj = {
    mac:
      window.localStorage.getItem("device_id") ||
      "e7227e32-777a-4b1e-a19a-259963159dee",
    name: getOS(),
  };

  var check = devices.find((d) => d.field_dispositivo_mac === dataObj.mac);

  if (!check) {
    const ip = await getIp();
    dataObj.ip = ip.data.geoplugin_request;
    dataObj.pais_dispositivo = ip.data.geoplugin_countryName;
    var response = await saveDevice(dataObj, currentOperator.operador);
    if (response.status == 201) {
      dataObj.node_id = response.data.nid[0].value;
    }
    Storage.set("current_device", dataObj);
  }

  return true;
}

function getMaxDevices(operator) {
  const config = Storage.get("basic_config");
  let maxDevices = 0;
  if (operator.max_devices > 0) {
    maxDevices = operator.max_devices;
  } else {
    let maxDevicesGlobal = 5;
    if (config && config.length > 0) {
      const maxDevicesConfig = config.find((x) => x.key === "max_devices");
      maxDevicesGlobal = maxDevicesConfig.value;
    }

    maxDevices = maxDevicesGlobal;
  }

  return maxDevices;
}

export async function verifyDeviceInExistingSessions(operator, userId, page) {  
  let devicesResponse = await getDevices(userId, operator.operador);
  let devices = devicesResponse.data;

  const dataObj = {
    mac:
      window.localStorage.getItem("device_id") ||
      "e7227e32-777a-4b1e-a19a-259963159dee",
    name: getOS(),
  };

  var deviceExists = devices.find(
    (d) => d.field_dispositivo_mac === dataObj.mac
  );

  let maxDevices = getMaxDevices(operator);

  let maxDevicesReached = maxDevices <= devices.length;

  if (maxDevicesReached && !deviceExists) {
    const config = Storage.get("basic_config");
    const alertDuration = config.find((x) => x.key === "alert_timeout").value;

    page.tag("Alert").message = Language.translate("security_max_devices_reached");
    page.tag("Alert").type = errorColor;
    page.tag("Alert").showAlert(alertDuration);
    return false;
  }

  if (!deviceExists) {
    const ip = await getIp();
    dataObj.ip = ip.data.geoplugin_request;
    dataObj.pais_dispositivo = ip.data.geoplugin_countryName;
    var response = await saveDevice(dataObj, operator.operador);
    if (response.status == 201) {
      dataObj.node_id = response.data.nid[0].value;
    }
    Storage.set("current_device", dataObj);

    devicesResponse = await getDevices(userId, operator.operador);
    devices = devicesResponse.data;
  }

  page.tag("Settings").max_devices = `${devices.length}/${maxDevices}`;
  return true;
}
export async function removeAllDevices() {
  //remove this device for all operators.
  const userId = Storage.get("current_user_id");
  const current_device = Storage.get("current_device");
  const operatorsResponse = await getOperators(userId);
  operatorsResponse.data.forEach(async (element) => {
    const devicesResponse = await getDevices(userId, element.operador);
    devicesResponse.data.forEach(async (element) => {
      if (element.field_dispositivo_mac === current_device.mac) {
        await removeDevice(element.node_id);
      }
    });
  });

  Storage.remove("current_device");

  return true;
}
export async function removeDeviceFromOperator(operator) {
  const userId = Storage.get("current_user_id");
  const current_device = Storage.get("current_device");
  const devicesResponse = await getDevices(userId, operator.operador);
  devicesResponse.data.forEach(async (element) => {
    if (element.field_dispositivo_mac === current_device.mac) {
      await removeDevice(element.node_id);
    }
  });
}

export function getOS() {
  var OSName = "Unknown OS";

  if (navigator.appVersion.indexOf("Android") != -1) OSName = "Android";
  else if (navigator.appVersion.indexOf("Web0S") != -1) OSName = "LG/SmartTV";
  else if (navigator.appVersion.indexOf("Tizen") != -1) OSName = "Samsung TV";
  else if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  else if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  else if (navigator.appVersion.indexOf("iOS") != -1) OSName = "iOS";
  else if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  else if (navigator.appVersion.indexOf("Opera") != -1) OSName = "Opera";
  else if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

  return OSName;
}
