import { Language } from "@lightningjs/sdk";
import { axiosClient } from "../Client";
import { Storage } from "@lightningjs/sdk";
import moment from "moment";
import 'moment/locale/es';

export function getPlayerConfig() {
  return axiosClient.get(`api/configuracion-json/videoplayer?_format=json`);
}
export function getPlayerConfigByOperator(operator) {
  return axiosClient.get(`/json_app_oktv/videoplayer/${operator}?_format=json`);
}
export function getBasicConfig() {
  return axiosClient.get(
    `api/configuracion-json/parametros-basicos?_format=json`
  );
}
export function getLangConfig() {
  return axiosClient.get(`/api/configuracion-json/idiomas?_format=json`);
}
export function getLang(lang) {
  return axiosClient.get(`/api/dictionary/tv/${lang}?_format=json`);
}
export async function configureLang() {
  const basicConfig = Storage.get("basicConfig");
  var defaultLang = basicConfig?.find((x) => x.key === "language_default");
  const lang = Storage.get("selectedLanguage") || defaultLang?.value || "es";
  moment.locale(lang);
  const langResponse = await getLang(lang);
  if(langResponse.status !== 200) {
    return;
  }
  const langData = langResponse.data;
  let translationsObject = {};
  langData.forEach((element) => {
    translationsObject[element.field_key] = element.field_value;
  });
  const translations = {};
  translations[lang] = translationsObject;
  Language.translations(translations);
  Language.set(lang);
}
