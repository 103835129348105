import { Colors, Lightning, Router, Storage } from "@lightningjs/sdk";
import { primaryColor } from "../Lib/Common";

export class DeviceItem extends Lightning.Component {
  static _template() {
    return {
      h: 120,
      w: 320,
      collision: true,
      Focus: {
        rect: true,
        w: (w) => w,
        h: (h) => h,
        mountY: 0,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 0,
          strokeColor: primaryColor,
          blend: 1,
          fillColor: Colors("#0A0A0A").alpha(0.8).get(),
        },
      },
      Content: {
        rect: true,
        w: (w) => w,
        h: (h) => h - 12,
        mountY: 0,
        color: Colors("#0A0A0A").alpha(0.8).get(),
        flex: { direction: "column" },
        Title: {
          y: 10,
          x: 10,
          text: {
            text: "Device",
            fontSize: 28,
            maxLines: 1,
            wordWrapWidth: 180,
            wordWrap: false,
            textOverflow: "ellipsis",
          },
        },
        Subtitle: {
          x: 10,
          alpha: 1,
          text: {
            text: "IP",
            fontSize: 20
          },
        },
        Mac: {
          x: 10,
          alpha: 1,
          text: {
            text: "32ce090e-dc2e-4541-8f4b-9bd27793878e",
            fontSize: 16,
            maxLines: 1,
            wordWrapWidth: 100
          },
        },
      },
    };
  }
  _init() {
    this._focusAnimation = this.animation({
      duration: 0.3,
      actions: [
        {
          t: "Focus",
          p: "shader.fillColor",
          v: {
            0: Colors("#0A0A0A").alpha(0.8).get(),
            1: primaryColor,
          },
        },
      ],
    });
  }
  _active() {
    this.tag("Title").text.text = this.device.field_dispositivo_nombre;
    this.tag("Subtitle").text.text = this.device.field_dispositivo_ip;
    this.tag("Mac").text.text = this.device.field_dispositivo_mac;
  }

  _focus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _unfocus() {
    this._focusAnimation.stop();
  }
  static get height() {
    return 120;
  }

  static get width() {
    return 320;
  }
}
