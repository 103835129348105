import { Language, Lightning, Utils } from "@lightningjs/sdk";
import { bgColor } from "../../Lib/Common";
import { getOS } from "../../Lib/Security";

export class NotFoundPage extends Lightning.Component {
  static _template() {
    return {
      Background: {
        rect: true,
        w: 1920,
        h: 1080,
        color: bgColor,
      },
      Logo: {
        mountX: 0.5,
        mountY: 1,
        x: 960,
        y: 600,
        src: Utils.asset("images/logo.png"),
      },
      Text: {
        mount: 0.5,
        x: 960,
        y: 720,
        text: {
          text: Language.translate("not_found_page_title"),
          fontFace: "Regular",
          fontSize: 64,
          textColor: 0xbbffffff,
        },
      },
    };
  }

  _handleAppClose() {
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      webOS.platformBack();
    }else if(sistemaOperativo === "Samsung TV"){
      window.tizen.application.getCurrentApplication().exit();
    }else{
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _handleBack() {
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      webOS.platformBack();
    }else if(sistemaOperativo === "Samsung TV"){
      window.tizen.application.getCurrentApplication().exit();
    }else{
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _init() {
    this.tag("Background")
      .animation({
        duration: 15,
        repeat: -1,
        actions: [
          {
            t: "",
            p: "color",
            v: {
              0: { v: 0xff416381 },
              0.5: { v: 0xff122B49 },
              0.8: { v: 0xff527794 },
            },
          },
        ],
      })
      .start();
  }
}
