
import axios from "axios";
import { axiosClient } from "../Client";

export function getChannels(operator) {
      return axiosClient.get(`json_app_oktv/canales/${operator}?_format=json`);
}

export function getNationalChannels(operator) {
      return axiosClient.get(`json_app_oktv/canales_nacionales/${operator}?_format=json`);
}

export function getCategories(operator) {
      return axiosClient.get(`json_app_oktv/categorias-canales/${operator}?_format=json`);
}

export function getChannelsByCategories(operator, category) {
      return axiosClient.get(`json_app_oktv/canales-by-category/${operator}/${category}?_format=json`);
}
export function getFavoritesChannel(userId, operator) {
      return axiosClient.get(`json_app_oktv/canales_favoritos_usuario/${userId}/${operator}?_format=json`)
}

export function markAsFavorite(channelId, operator) {
      return axiosClient.post('/node?_format=json', JSON.stringify({"type":[{"target_id":"canales_favoritos_por_usuario"}],"title":[{"value":"Canal Favorito"}],"field_canal":[{"target_id": channelId}],"field_operador":[{"value": operator}]}))
}
export function removeNode(nodeId) {
      return axiosClient.delete(`/node/${nodeId}?_format=json`);
}

export function getEpg(url) {
      return axios.get(url);
}