import { Lightning,Utils } from "@lightningjs/sdk";
import { Grid } from "@lightningjs/ui";
import ChannelItem from "./ChannelItem";
import { bgColor } from "../../Lib/Common";
import LoadingIndicator from "../LoadingIndicator";

export default class ChannelsControl extends Lightning.Component {
  static _template() {
    return {
      w: 1600,
      h: 400,
      collision: true,
      LoadingIndicator: {
        type: LoadingIndicator,
        x: 480,
        y:30,
        width: 900
      },
      Title: {
        text: {
          fontSize: 34,
        },
      },
      Items: {
        y: 50,
        w: 1380,
        h: 200,
        type: Grid,
        itemType: ChannelItem,
        spacing: 20,
        direction: "row",
        collision: true
      },
      ChevronWrapper: {
        rect: true,
        alpha: 0,
        color: bgColor,
        x: 1380,
        y: 150,
        mountY: 0.5,
        w: 220,
        h: 200,
        Icon: {
          x: 100,
          y: 100,
          w: 24,
          h: 24,
          mount: 0.5,
          src: Utils.asset("images/icons/arrow_right.png"),
        },
      },
    };
  }

  set items(items) {
    this.tag("Items").reload(items);
    this.patch({
      ChevronWrapper: {
        smooth: {
          alpha: [items.length > 6 ? 1 : 0],
        },
      },
    });
  }
  async _active() {
    this.tag("LoadingIndicator").show();
    this.title = this.category.category;
    this.items = this.category.items.map((item) => ({ channel: item }));
    this._readyInit = true;
    this.tag("LoadingIndicator").hide();
  }

  set title(title) {
    this.patch({
      Title: {
        smooth: {
          text: { text: title },
        },
      },
    });
  }

  _handleRight() {
    const grid = this.tag("Items");
    if (grid.index <= items.length - 1) {
      grid.right();
    }
  }

  _getFocused() {
    return this.tag("Items");
  }
  
  static get height() {
    return 250;
  }

  static get width() {
    return 1380;
  }
}
