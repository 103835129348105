import { Colors, Language, Lightning, Router, Storage } from "@lightningjs/sdk";
import { primaryColor } from "../Lib/Common";
import { configureLang } from "../Services/Api/Routes/Config";

export class LanguageItem extends Lightning.Component {
  static _template() {
    return {
      h: 100,
      w: 200,
      collision: true,
      Focus: {
        rect: true,
        w: (w) => w,
        h: (h) => h,
        mountY: 0,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 0,
          strokeColor: primaryColor,
          blend: 1,
          fillColor: Colors("#0A0A0A").alpha(0.8).get(),
        },
      },
      Content: {
        rect: true,
        w: (w) => w,
        h: (h) => h - 12,
        mountY: 0,
        color: Colors("#0A0A0A").alpha(0.8).get(),
        flex: { direction: "column" },
        Title: {
          y: 10,
          x: 10,
          text: {
            text: "Test",
            fontSize: 28,
            maxLines: 1,
            wordWrapWidth: 180,
            wordWrap: false,
            textOverflow: "ellipsis",
          },
        },
        Subtitle: {
          x: 10,
          alpha: 0,
          text: {
            text: Language.translate("current_lang_indicator"),
            fontSize: 20,
          },
        },
      },
    };
  }
  _init() {
    this._focusAnimation = this.animation({
      duration: 0.3,
      actions: [
        {
          t: "Focus",
          p: "shader.fillColor",
          v: {
            0: Colors("#0A0A0A").alpha(0.8).get(),
            1: primaryColor,
          },
        },
      ],
    });
  }
  async _handleEnter() {
    const currentLang = Storage.get("selectedLanguage");
    if (currentLang === this.lang.langCode) {
      return;
    }
    Storage.set("selectedLanguage", this.lang.langCode);
    await configureLang();
    window.location.reload();
  }
  _active() {
    this.tag("Title").text.text = this.lang.name;
    const currentLang = Storage.get("selectedLanguage");
    this.tag("Subtitle").alpha =
      this.lang.langCode === currentLang ? 1 : 0;
  }

  _focus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _unfocus() {
    this._focusAnimation.stop();
  }
  static get height() {
    return 100;
  }

  static get width() {
    return 200;
  }
}
