import {
  Lightning,
  Router,
  Colors,
  Utils,
  Storage,
  Language,
} from "@lightningjs/sdk";
import {
  Grid,
  InputField,
  Keyboard,
  Key as BaseKey,
} from "@lightningjs/ui";
import Button from "../Components/Button";
import { max } from "moment";

export default class PinConfig extends Lightning.Component {
  static _template() {
    return {
      rect: true,
      zIndex: 99999,
      w: 1920,
      h: 150,
      color: Colors("#5271FF").get(),
      x: 0,
      y: -150,
      transitions: {
        x: {
          duration: 0.3,
          timingFunction:
            "cubic-bezier(0.17, 0.9, 0.32, 1.3)",
        },
        w: {
          duration: 0.3,
          timingFunction:
            "cubic-bezier(0.17, 0.9, 0.32, 1.3)",
        },
      },
      TitleBg: {
        w: 1920,
        h: 150,
        color: Colors("#5271FF").get(),
        zIndex: 3,
        rect: true,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: [0, 0, 0, 0],
        },
      },
      Title: {
        y: (h) => h / 2,
        x: 120,
        mountY: 0.5,
        zIndex: 3,
        text: {
          text: "Configuración del PIN",
          fontSize: 40,
          fontStyle: "bold",
        },
      },
      Background: {
        w: (w) => w,
        y: -1570,
        h: 1080,
        zIndex: 1,
        rect: true,
        color: Colors("#000000").get(),
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: [0, 0, 0, 0],
        },
      },
      Grid: {
        x: 0,
        y: -1570,
        w: 960,
        h: 1080,
        zIndex: 2,
        flex: {
          direction: "column",
        },
        PinInputFieldWrapper: {
          collision: true,
          x: 480,
          y: 200,
          rect: true,
          h: 90,
          w: 960,
          color:
            Colors("#ffffff").get(),
          shader: {
            type: Lightning.shaders
              .RoundedRectangle,
            radius: 20,
          },
          PinInputField: {
            x: 20,
            y: 20,
            type: InputField,
            description:
              Language.translate(
                "input_pin"
              ),
            autoHideCursor: false,
            maxCharacters: 4,
            inputText: {
              paddingLeft: 20,
              textColor:
                Colors("#121212").get(),
              maxCharacters: 4,
            },
          },
        },
        KeyboardWrapper: {
          alpha: 1,
          mountX: 0.5,
          x: 960,
          y: 250,
          rect: true,
          shader: {
            type: Lightning.shaders
              .RoundedRectangle,
            radius: 20,
          },
          h: 80,
          w: 880,
          color:
            Colors("#1D2228").get(),
          MyKeyboard: {
            y: 10,
            w: 880,
            h: 300,
            type: Keyboard,
            alpha: 1,
            config: myKeyboardConfig,
            collision: true,
            currentLayout: "a",
            maxCharacters: 4,
            signals: { onDone: true },
          },
        },
      },
    };
  }
  onDone(event) {
    let pinEntered = this.tag(
      "PinInputField"
    ).input;

    if (pinEntered.length < 4) {
      //todo show alert
      return;
    }

    if (
      this.tag("PinInputField")
        .description ==
      Language.translate(
        "input_old_pin"
      )
    ) {
      var pinSecret = Storage.get(
        "pin_secret"
      );
      if (pinEntered != pinSecret) {
        //todo show alert of invalid pin
        return;
      }

      this.tag(
        "PinInputField"
      ).description =
        Language.translate("input_pin");
      this.tag("MyKeyboard").clear();
      this.focusedPin();
      return;
    }

    if (
      this.tag("PinInputField")
        .description ==
        Language.translate(
          "input_validation_pin"
        )
    ) {
      var pinSecret = Storage.get(
        "pin_secret"
      );
      if (pinEntered != pinSecret) {
        //todo show alert of invalid pin
        return;
      }

      Router.navigate("channel", {
        channel: Storage.get("channel"),
      });
      Router.focusPage();
      return;
    }

    Storage.set(
      "pin_secret",
      pinEntered
    );
    Storage.set("pin_configured", true);

    this.tag("MyKeyboard").clear();
    Router.focusPage();
  }

  _focus() {
    var channel = Storage.get("channel");
    if (channel != null) {
      this.tag("Title").patch({
        text: {
          text: "Canal protegido, ingrese el PIN",
        },
      });

      this.tag("MyKeyboard").clear();
      this.tag(
        "PinInputField"
      ).description =
        Language.translate(
          "input_validation_pin"
        );
    } else {
      var pinConfigured = Storage.get(
        "pin_configured"
      );
      if (pinConfigured) {
        this.tag("Title").patch({
          text: {
            text: "Cambiar PIN",
          },
        });

        this.tag("MyKeyboard").clear();
        this.tag(
          "PinInputField"
        ).description =
          Language.translate(
            "input_old_pin"
          );
      } else {
        this.tag("Title").patch({
          text: {
            text: "Configuración del PIN",
          },
        });
      }
    }

    this.patch({
      smooth: {
        y: 0,
      },
      Grid: {
        y: 180,
      },
      Background: {
        y: 150,
      },
    });

    this.focusKeyboard();
  }

  focusKeyboard() {
    this.refComponentFocus =
      "MyKeyboard";
    this.patch({
      KeyboardWrapper: {
        smooth: {
          alpha: 1,
        },
      },
    });
    this.focusedPin();
  }
  _getFocused() {
    return this.tag(
      this.refComponentFocus ??
        "MyKeyboard"
    );
  }
  focusedPin() {
    this.tag("MyKeyboard").inputField(
      this.tag("PinInputField")
    );

    this.tag(
      "PinInputField"
    ).toggleCursor(true);

    this.tag(
      "PinInputFieldWrapper"
    ).color = Colors("#eeeeee").get();
  }
  _unfocus() {
    this.patch({
      smooth: {
        y: -150,
      },
      Grid: {
        y: -1570,
      },
      Background: {
        y: -1570,
      },
    });

    Storage.remove("channel");
  }

  _handleUp() {
    Router.focusPage();
  }

  _handleDown() {
    Router.focusPage();
  }
}

// #region Keys config
class Key extends BaseKey {
  static _template() {
    return {
      ...super._template(),
      rect: true,
      collision: true,
    };
  }
  _firstActive() {
    this.label = {
      mountY: 0.45,
      text: {
        fontSize: 40,
      },
    };
    this.labelColors = {
      unfocused: Colors("white").get(),
      focused: Colors("#3C4043").get(),
    };

    this.backgroundColors = {
      unfocused:
        Colors("#2A3139").get(),
      focused: Colors("white").get(),
    };
    if (this.hasFocus()) {
      this._focus();
    }
  }
  static get height() {
    return 60;
  }
  static get width() {
    return 60;
  }
}

class ActionKey extends BaseKey {
  _active() {
    this.label = {
      mountY: 0.5,
      mountX: 0.5,
      text: {
        fontSize: 40,
      },
    };
    this.labelColors = {
      unfocused: Colors("white").get(),
      focused: Colors("#3C4043").get(),
    };
    this.backgroundColors = {
      unfocused:
        Colors("#2A3139").get(),
      focused: Colors("white").get(),
    };
    if (this.hasFocus()) {
      this._focus();
    }
  }

  static get height() {
    return 60;
  }

  static get width() {
    return 100;
  }
}

class IconKey extends ActionKey {
  set icon(src) {
    this._icon = src;
    //call for _update
    this._update();
  }

  get icon() {
    return this._icon;
  }

  _update() {
    if (!this.active) {
      //blocking update if not active.
      return;
    }
    const hasFocus = this.hasFocus();
    let {
      focused,
      unfocused = 0xff000000,
    } = this.backgroundColors;
    //Use labelColors and label to color the icon.
    let {
      focused: labelFocused,
      unfocused:
        labelUnfocused = 0xffffffff,
    } = this.labelColors;
    this.patch({
      Background: {
        color:
          hasFocus && focused
            ? focused
            : unfocused,
      },
      Label: {
        src: Utils.asset(
          hasFocus && labelFocused
            ? `${this.icon}_unfocused.png`
            : `${this.icon}_focused.png`
        ),
        color:
          hasFocus && labelFocused
            ? labelFocused
            : labelUnfocused,
        w: 40,
        h: 40,
      },
    });
  }
}

const myKeyboardConfig = {
  layouts: {
    a: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Backspace",
        "Done",
      ],
    ],
  },
  styling: {
    align: "center",
    horizontalSpacing: 5,
    verticalSpacing: 5,
  },
  buttonTypes: {
    default: {
      type: Key,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
    },
    Backspace: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/backspace",
    },
    Clear: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/close-circle-outline",
    },
    Done: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/check-circle-outline",
    },
  },
};
// #endregion
