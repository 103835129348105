import { Colors, Lightning, Storage } from "@lightningjs/sdk";
import { Grid } from "@lightningjs/ui";
import EPGRow from "./EPGRow";
import XmlReader from "xml-reader";
import { getChannels, getEpg } from "../../Services/Api/Routes/Channels";
import moment from "moment";
import LoadingIndicator from "../LoadingIndicator";

export default class EPGMain extends Lightning.Component {
  static _template() {
    return {
      Day: {
        text: {
          text: "",
          fontSize: 24,
          textColor: Colors("white").alpha(0.5).get(),
        },
      },
      LoadingIndicator: {
        type: LoadingIndicator,
        x: 510,
        width: 900,
      },
      Items: {
        y: 50,
        w: 1380,
        h: 550,
        forceLoad: false,
        type: Grid,
        itemType: EPGRow,
        spacing: 10,
        direction: "column",
      },
    };
  }
  parseXml() {
    const reader = XmlReader.create();
    reader.on("tag:programme", (data) => {
      const endDate = moment(data.attributes.stop, "YYYYMMDDhhmmss Z");
      const today = moment();
      const canShow = today <= endDate;
      if (!canShow) return;

      const channel = this._epgChannels.find(
        (x) => data.attributes.channel === x.epgChannel.attributes.id
      );

      if (channel) {
        channel.epgChannel.programs.push({ programmer: data });
      }
    });
    reader.on("tag:channel", (data) => {
      const returnObj = {
        epgChannel: { ...data, channel: { posicion: 0 } },
      };
      const channelInJson = this._allChannels.find(
        (x) => x.EPG_ID_Channel === data.attributes.id
      );

      if (channelInJson) {
        returnObj.epgChannel.channel = channelInJson;
        returnObj.epgChannel.programs = [];
        this._epgChannels.push(returnObj);
      }
    });
    reader.on("done", async (data) => {
      await this.initializeRender();
    });
    reader.parse(this._epgsData);
  }

  async fetchData() {
    const config = Storage.get("basic_config");
    var urlEpg = config.find((x) => x.key === "url_epg");
    const epgs = await getEpg(urlEpg.value);
    this._epgsData = epgs.data;
    let operator = Storage.get("current_operator");
    this._allChannels = (await getChannels(operator.operador)).data;
  }
  async _firstActive() {
    this.tag("LoadingIndicator").show();
    this.tag("Day").text.text = moment().format("ddd DD/MM");
    await this.fetchData();

    this._epgChannels = [];

    this.parseXml();

    this.tag("LoadingIndicator").hide();
  }

  async initializeRender() {
    if (this._readyInit || this._isInit || !this._epgChannels) return;
    this._isInit = true;
    this.tag("LoadingIndicator").show();
    this._epgChannels = this._epgChannels.filter(
      (x) => x.epgChannel.channel.EPG_ID_Channel
    );
    this._epgChannels.sort(
      (a, b) =>
        parseFloat(a.epgChannel.channel.posicion) -
        parseFloat(b.epgChannel.channel.posicion)
    );
    this.tag("Items").reload(this._epgChannels);
    this._readyInit = true;
    this._isInit = false;
    this.tag("LoadingIndicator").hide();
  }

  _getFocused() {
    return this.tag("Items");
  }

  static get height() {
    return 400;
  }

  static get width() {
    return 1380;
  }
}
