import { Lightning, Router, Colors, Utils, Storage, Language } from "@lightningjs/sdk";
import MenuItem from "./MenuItem";
const leftMargin = 40;
const MenuIndex ={
  Channels: 0,
  Favorites: 1,
  EPG: 2,
  Settings: 3,
  Logout: 4
}

export default class Menu extends Lightning.Component {
  static _template() {
    return {
      rect: true,
      w: 280,
      h: 730,
      color: Colors("#0A0A0A").get(),
      transitions: {
        x: {
          duration: 0.3,
          timingFunction: "cubic-bezier(0.17, 0.9, 0.32, 1.3)",
        },
        w: {
          duration: 0.3,
          timingFunction: "cubic-bezier(0.17, 0.9, 0.32, 1.3)",
        },
      },
      Image: {
        x: 140,
        y: 630,
        w: 100,
        h: 100,
        mount: 0.5,
        src: Utils.asset("images/logo.png"),
      },
      Items: {
        y: 30,
        h: 730,
        w: 280,
        collision: true,
        flex: { direction: "column" },
        Channels: {
          x: leftMargin,
          collision: true,
          type: MenuItem,
          label: Language.translate("menu_channels"),
          pageId: "home",
        },
        Favorites: {
          x: leftMargin,
          collision: true,
          type: MenuItem,
          label: Language.translate("menu_favs"),
          pageId: "favorites",
        },
        EPG: {
          x: leftMargin,
          collision: true,
          type: MenuItem,
          label: Language.translate("menu_epg"),
          pageId: "epg",
        },
        Settings: {
          x: leftMargin,
          collision: true,
          type: MenuItem,
          label: Language.translate("menu_settings"),
          pageId: "settings",
        },
        Logout: {
          x: leftMargin,
          collision: true,
          type: MenuItem,
          label: Language.translate("menu_logout"),
          action: "$logout",
          pageId: "exit"
        },
      },
    };
  }
      
  _init() {
    this._index = 0;
  }

  _handleUp() {
    if (this._index > 0) {
      this._index--;
      this.change && this.fireAncestors(this.change)
    }
  }

  _handleDown() {
    if (this._index < this.tag("Items").children.length - 1) {
      this._index++;
      this.change && this.fireAncestors(this.change)
    }
  }

  get activeItem() {
    return this.tag("Items").children[this._index];
  }

  _getFocused() {
    return this.activeItem;
  }

  _focus() {
    this.activeItem.activeStatus();
  }

  _unfocus() {
    this.activeItem.activeStatus();
  }

  async $logout() {
    this.fireAncestors("$logout");
  }
  
  _handleClick(target, coords) {
    if(this.activeItem.ref !== target.ref){
      this.activeItem._unfocus();
    }
    
    if(!this.hasFocus()){
      this.fireAncestors(this.focus, "menu");
    }
    if(target.ref == "Channels"){
      this._index = MenuIndex.Channels;
    }else if(target.ref == "Favorites"){
      this._index = MenuIndex.Favorites;
    }else if(target.ref == "EPG"){
      this._index = MenuIndex.EPG;
    }else if(target.ref == "Settings"){
      this._index = MenuIndex.Settings;
    }else if(target.ref == "Logout"){
      this._index = MenuIndex.Logout;
      this.$logout();
    }
    this._refocus();
    this.change && this.fireAncestors(this.change)
  }
}
