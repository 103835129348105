import {
  Language,
  Lightning,
  Metadata,
  Storage,
} from "@lightningjs/sdk";
import { Grid } from "@lightningjs/ui";
import { OperatorItem } from "./OperatorItem";
import Button from "../Components/Button";
import { DeviceItem } from "./DeviceItem";
import { removeDeviceFromOperator } from "../Lib/Security";
import { LanguageItem } from "./LanguageItem";
const UIIndex = {
  Operators: 2,
  Refresh: 8,
  PinButton: 7,
  LangPicker: 6,
  Devices: 4,
};
export class SettingsTile extends Lightning.Component {
  static _template() {
    return {
      flex: { direction: "column" },
      w: 1400,
      h: 600,
      Title: {
        text: {
          fontSize: 34,
          text: Language.translate(
            "operators_title"
          ),
        },
      },
      Description: {
        text: {
          fontSize: 24,
          text: Language.translate(
            "operators_opts_title"
          ),
        },
      },
      Items: {
        w: 1380,
        h: 100,
        type: Grid,
        itemType: OperatorItem,
        spacing: 20,
        direction: "row",
        collision: true,
      },
      TitleDevice: {
        flexItem: { marginTop: 20 },
        text: {
          fontSize: 34,
          text: Language.translate(
            "devices_title"
          ),
        },
      },
      Devices: {
        w: 1380,
        h: 120,
        type: Grid,
        itemType: DeviceItem,
        spacing: 20,
        direction: "row",
        collision: true,
      },
      TitleLang: {
        flexItem: { marginTop: 20 },
        text: {
          fontSize: 34,
          text: Language.translate(
            "log_in_lang_picker"
          ),
        },
      },
      LanguagePicker: {
        w: 1380,
        h: 100,
        type: Grid,
        itemType: LanguageItem,
        spacing: 20,
        direction: "row",
        collision: true,
      },
      PinButton: {
        collision: true,
        flexItem: {
          marginTop: 20,
        },
        x: 0,
        h: 80,
        w: 300,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        ref: "PinButton",
        type: Button,
        label: Storage.get("pin_configured")
          ? Language.translate(
              "change_pin"
            )
          : Language.translate(
              "set_pin"
            ),
        action: "$openSetPin",
      },
      Refresh: {
        collision: true,
        flexItem: { marginLeft: 20 },
        x: 320,
        y: -80,
        h: 80,
        w: 300,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        ref: "RefreshButton",
        type: Button,
        label:
          Language.translate("refresh"),
        action: "$refresh",
      },
      Version: {
        flexItem: { marginLeft: 20 },
        x: 600,
        y: 20,
        text: {
          text: Language.translate(
            "version_app_tile",
            {
              version:
                Metadata.appVersion(),
            }
          ),
        },
      },
    };
  }
  _init() {
    this._index = UIIndex.Operators;
  }
  set lang(items) {
    if (items && items.length > 0)
      this.tag("LanguagePicker").reload(
        items
      );
    this.tag(
      "LanguagePicker"
    ).wrapper.collision = true;
  }
  set operators(items) {
    if (items && items.length > 0)
      this.tag("Items").reload(items);
    this.tag(
      "Items"
    ).wrapper.collision = true;
  }
  set devices(items) {
    if (items && items.length > 0)
      this.tag("Devices").reload(items);
    this.tag(
      "Devices"
    ).wrapper.collision = true;
  }
  set max_devices(devicesString) {
    this.tag(
      "TitleDevice"
    ).text.text = `${Language.translate(
      "devices_title"
    )} ${devicesString}`;
  }
  $openSetPin() {
    this.fireAncestors("$openSetPin");
  }
  $refresh() {
    this.fireAncestors("$refresh");
  }
  async $canChangeDevice(
    operator,
    currentOperator
  ) {
    const can = await this.signal(
      "canChangeDevice",
      operator
    );
    if (can) {
      await removeDeviceFromOperator(
        currentOperator
      );
      Storage.set(
        "current_operator",
        operator
      );
      this.fireAncestors("$refresh");
    }
  }
  _getFocused() {
    return this.children[this._index];
  }
  _handleUp() {
    if (
      this._index === UIIndex.Refresh
    ) {
      this._index = UIIndex.PinButton;
    } else if (
      this._index === UIIndex.PinButton
    ) {
      this._index = UIIndex.LangPicker;
    } else if (
      this._index === UIIndex.LangPicker
    ) {
      this._index = UIIndex.Devices;
    } else if (
      this._index === UIIndex.Devices
    ) {
      this._index = UIIndex.Operators;
    }
  }
  _handleDown() {
    if (
      this._index === UIIndex.Operators
    ) {
      this._index = UIIndex.Devices;
    } else if (
      this._index === UIIndex.Devices
    ) {
      this._index = UIIndex.LangPicker;
    } else if (
      this._index === UIIndex.LangPicker
    ) {
      this._index = UIIndex.PinButton;
    } else if (
      this._index === UIIndex.PinButton
    ) {
      this._index = UIIndex.Refresh;
    }
  }
  _handleLeft() {
    if (
      this._index === UIIndex.Refresh
    ) {
      this._index = UIIndex.PinButton;
    } else {
      return false;
    }
  }
  _handleRight() {
    if (
      this._index === UIIndex.PinButton
    ) {
      this._index = UIIndex.Refresh;
    } else {
      return false;
    }
  }
  _handleClick(target, coords) {
    if (target.ref === "PinButton") {
      this._index = UIIndex.PinButton;
      this.$openSetPin();
    } else if (
      target.ref === "RefreshButton"
    ) {
      this._index = UIIndex.Refresh;
      this.$refresh();
    } else if (target.ref === "Items") {
      this._index = UIIndex.Operators;
    } else if (
      target.operator &&
      target.operator.id
    ) {
      target._handleEnter();
      const index = this.tag(
        "Items"
      ).items.findIndex(
        (x) =>
          x.operator.id ===
          target.operator.id
      );
      this.tag("Items").setIndex(index);
    } else if (
      target.ref === "Devices"
    ) {
      this._index = UIIndex.Devices;
    } else if (
      target.ref === "LanguagePicker"
    ) {
      this._index = UIIndex.LangPicker;
    } else if (
      target.lang &&
      target.lang.langCode
    ) {
      target._handleEnter();
      const index = this.tag(
        "LanguagePicker"
      ).items.findIndex(
        (x) =>
          x.lang.langCode ===
          target.lang.langCode
      );
      this.tag(
        "LanguagePicker"
      ).setIndex(index);
    }

    if (!this.hasFocus()) {
      this.fireAncestors(
        this.focus,
        "settings"
      );
    }
    this._refocus();
  }
}
