import {
  Colors,
  Language,
  Lightning,
  Log,
  Router,
  Storage,
  VideoPlayer,
} from "@lightningjs/sdk";
import { bgColor } from "../Lib/Common";
import { Alert, successColor } from "../Components/Alert";
import Button from "../Components/Button";
import { loader, unloader } from "../Lib/Hls";
import { dashLoader, dashUnloader } from "../Lib/Dash";
import { getPlayerConfig, getPlayerConfigByOperator } from "../Services/Api/Routes/Config";
import {
  getFavoritesChannel,
  markAsFavorite,
  removeNode,
} from "../Services/Api/Routes/Channels";
import { getOS } from "../Lib/Security";
import { mp4loader, mp4Unloader } from "../Lib/MP4.js";

const interfaceTimeout = 10000;

const UIIndex = {
  BackBtn: 0,
  TitleChannel: 1,
  StarBtn: 2,
  MuteBtn: 3,
};

export class ChannelPlayer extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      collision: true,
      rect: true,
      alpha: 1,
      color: bgColor,
      Alert: {
        type: Alert,
        zIndex: 9999
      },
      Ui: {
        color: Colors("#000").alpha(0.3).get(),
        texture: lng.Tools.getShadowRect(1920, 100, 0, 50, 15),
        Buttons: {
          x: 20,
          y: 20,
          flex: { direction: "row", justifyContent: "space-between" },
          BackButton: {
            type: Button,
            w: 160,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
            icon: "arrow-left-custom",
            action: "$back",
            ref: "BackButton",
            flexItem: { marginRight: 30 },
            collision: true
          },
          TitleChannel: {
            w: 450,
            text: {
              lineHeight: 90,
              verticalAlign: "middle",
              textAlign: "left",
              maxLines: 1,
              wordWrapWidth: 450,
              shadow: true,
            },
            flexItem: { marginRight: 910 },
          },
          FavoriteButton: {
            type: Button,
            w: 160,
            alpha: 1,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
            icon: "star",
            action: "$markFavorite",
            ref: "FavoriteButton",
            flexItem: { marginRight: 20 },
            collision: true
          },
          MuteButton: {
            type: Button,
            w: 160,
            shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
            icon: "volume-high",
            action: "$toggleMute",
            ref: "MuteButton",
            flexItem: { marginRight: 20 },
            collision: true
          },
        },
      },
    };
  }
  _handleClick(t,c){
    if (t.ref === "MuteButton") {
      this._index = UIIndex.MuteBtn;
      t._handleEnter();
    } else if (t.ref === "BackButton") {
      this._index = UIIndex.BackBtn;
      t._handleEnter();
    } else if(t.ref === "FavoriteButton") {
      this._index = UIIndex.StarBtn;
      t._handleEnter();
    }
    this._toggleInterface(true);
  }
  _init() {
    this._index = UIIndex.BackBtn;
    this._interfaceVisible = true;
    this._timeout = null;
    this._setInterfaceTimeout();

    this._toggleInterface(true);
  }

  async _active() {

    this.loadFavs();

    var config = await getPlayerConfig();
    Storage.set("playerConfig", config.data);
    const operator = Storage.get("current_operator");
    var configByOperator = await getPlayerConfigByOperator(operator.operador);
    Storage.set("playerConfigOperator", configByOperator.data);

    this._startVideo();
  }

  _inactive() {
    VideoPlayer.close();
  }

  async loadFavs() {
    const userId = Storage.get("current_user_id");
    const operator = Storage.get("current_operator");
    const favsResult = await getFavoritesChannel(userId, operator.operador);
    this._favorites = favsResult.data;

    this.changeFavIcon();
  }

  async _startVideo() {
    this.patch({
      smooth: {
        color: bgColor,
      },
    });

    VideoPlayer.close();
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      VideoPlayer.size(960, 540)
    }
    VideoPlayer.consumer(this);
    if (this._channel.tipo_transmision === "MP4") {
      VideoPlayer.loader(mp4loader);
      VideoPlayer.unloader(mp4Unloader);
    } else if (this._channel.tipo_transmision === "DASH") {
      VideoPlayer.loader(dashLoader);
      VideoPlayer.unloader(dashUnloader);
    } else {
      VideoPlayer.loader(loader);
      VideoPlayer.unloader(unloader);
    }

    VideoPlayer.open(this._channel.field_url, {
      debug: true,
    });
  }

  _toggleInterface(visible) {
    this.patch({
      Ui: {
        smooth: {
          alpha: [visible ? 1 : 0],
        },
      },
    });
    this.tag("Ui")
      .transition("alpha")
      .on("finish", () => {
        this._interfaceVisible = visible;
      });
    if (visible) {
      this._setInterfaceTimeout();
    }
  }

  _setInterfaceTimeout() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._toggleInterface(false);
    }, interfaceTimeout);
  }
  //#region Key mapping
  _captureKey(e) {
    this._toggleInterface(true);

    return (
      !this._interfaceVisible && e.key !== "ArrowUp" && e.key !== "ArrowDown"
    );
  }

  _handleLeft() {
    if (this._index === UIIndex.MuteBtn) {
      this._index = UIIndex.StarBtn;
    } else if (this._index === UIIndex.StarBtn) {
      this._index = UIIndex.BackBtn;
    } else {
      this._index = UIIndex.MuteBtn;
    }
  }

  _handleRight() {
    if (this._index === UIIndex.BackBtn) {
      this._index = UIIndex.StarBtn;
    } else if (this._index === UIIndex.StarBtn) {
      this._index = UIIndex.MuteBtn;
    } else {
      this._index = 0;
    }
  }

  _handleDown() {
    let index = this._channels.findIndex(
      (x) => x.title === this._channel.title
    );
    const lastIndex = this._channels.length - 1;
    if (this._channels.length - 1 > index) {
      this._channel = this._channels[index + 1];
      this.tag(
        "TitleChannel"
      ).text.text = `${this._channel.posicion} ${this._channel.title}`;
      this._startVideo();
    } else if (index == lastIndex && lastIndex > 0) {
      this._channel = this._channels[0];
      this.tag(
        "TitleChannel"
      ).text.text = `${this._channel.posicion} ${this._channel.title}`;
      this._startVideo();
    }
    this.changeFavIcon();
  }

  _handleUp() {
    let index = this._channels.findIndex(
      (x) => x.title === this._channel.title
    );
    const lastIndex = this._channels.length - 1;
    if (this._channels.length - 1 >= index && index > 0) {
      this._channel = this._channels[index - 1];
      this.tag(
        "TitleChannel"
      ).text.text = `${this._channel.posicion} ${this._channel.title}`;
      this._startVideo();
    } else if (index == 0 && lastIndex > 0) {
      this._channel = this._channels[lastIndex];
      this.tag(
        "TitleChannel"
      ).text.text = `${this._channel.posicion} ${this._channel.title}`;
      this._startVideo();
    }
    this.changeFavIcon();
  }

  _handleEnter() {
    this.$playPause();
  }

  _handleBack() {
    this.$back();
  }
  //#endregion

  _focus() {
    this._toggleInterface(true);
  }

  _getFocused() {
    return this.tag("Ui.Buttons").children[this._index];
  }

  pageTransition() {
    return "left";
  }

  //#region Button actions
  $playPause() {
    VideoPlayer.playPause();
  }

  $back() {
    this.patch({
      smooth: {
        color: bgColor,
      },
    });

    VideoPlayer.close();
    Router.back();
  }
  changeFavIcon() {
    if (this._channel.field_canal_id || this._favorites.some((x) => x.field_canal_id === this._channel.nid)) {
      //viene de favs y es fav
      this.tag("FavoriteButton").setSmooth("icon", "star-fav");
    } else {
      this.tag("FavoriteButton").setSmooth("icon", "star");
    }
  }
  $markFavorite() {
    if (this._channel.field_canal_id) {
      removeNode(this._channel.nid).then((result) => {
        this._channel.nid = this._channel.field_canal_id;
        this._channel.field_canal_id = undefined;
        this.tag("Alert").message = Language.translate("player_channel_fav_removed_alert");
        this.tag("Alert").type = successColor;
        this.tag("Alert").showAlert(3000);
        this.loadFavs();
      });
    } else {
      if (this._favorites.some((x) => x.field_canal_id === this._channel.nid)) {
        //viene de canales y es favorito
        const fav = this._favorites.filter(
          (x) => x.field_canal_id === this._channel.nid
        );
        removeNode(fav[0].nid).then((result) => {
          this.tag("Alert").message = Language.translate("player_channel_fav_removed_alert");
          this.tag("Alert").type = successColor;
          this.tag("Alert").showAlert(3000);
          this.loadFavs();
        });
      } else {
        // no es favorito, viene de canales o de favoritos
        const operator = Storage.get("current_operator");
        markAsFavorite(this._channel.nid, operator.operador).then((result) => {
          this._channel.field_canal_id = this._channel.nid;
          this._channel.nid = result.data.nid[0].value;
          this.tag("Alert").message = Language.translate("player_channel_fav_added_alert");
          this.tag("Alert").type = successColor;
          this.tag("Alert").showAlert(3000);
          this.loadFavs();
        });
      }
    }
  }

  $toggleMute() {
    VideoPlayer.mute(!VideoPlayer.muted);
  }
  //#endregion
  //#region Hooks for VideoPlayer events
  $videoPlayerEvent(event, eventData) {
    // Log.info(event, { event, eventData });
  }
  $videoPlayerPlaying() {
    this.setSmooth("color", 0x00000000);
  }

  $videoPlayerPause() {
    this.setSmooth("color", bgColor);
  }

  $videoPlayerVolumeChange() {
    this.tag("Ui.Buttons.MuteButton").icon =
      VideoPlayer.muted === true ? "volume-off" : "volume-high";
  }

  $videoPlayerEnded() {
    VideoPlayer.reload();
  }

  $videoPlayerError(data) {
    Log.error("Error playing video", data);
  }
  //#endregion
}
