import {
  Colors,
  Lightning,
  Router,
  Img,
  Storage,
} from "@lightningjs/sdk";
import { focusedColor } from "../../Lib/Common";

const widthBox = 200;
const widthImage = 200;
const baseImageUrl =
  process.env.APP_BACKEND_URL ||
  `https://devportal.ok-television.com`;
export default class ChannelItem extends Lightning.Component {
  static _template() {
    return {
      h: widthBox,
      w: widthBox,
      collision: true,
      Focus: {
        rect: true,
        w: (w) => w,
        h: (h) => h + 12,
        mountY: 0,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 0,
          stroke: 0,
          strokeColor: focusedColor,
          blend: 1,
          fillColor: Colors("white")
            .alpha(0)
            .get(),
        },
      },
      Icon: {
        mountY: 0.5,
        mountX: 0.5,
        x: (w) => w / 2,
        y: (h) => h / 2,
        w: widthImage,
        h: widthImage,
      },
    };
  }

  _init() {
    this._focusAnimation =
      this.animation({
        duration: 0.3,
        actions: [
          {
            t: "Focus",
            p: "shader.fillColor",
            v: {
              0: Colors("white")
                .alpha(0)
                .get(),
              1: focusedColor,
            },
          },
        ],
      });
  }

  _active() {
    const url =
      baseImageUrl +
      this.channel.field_logo;
    this.patch({
      Icon: Img(url).contain(
        widthImage,
        widthImage
      ),
    });
  }

  _focus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _unfocus() {
    this._focusAnimation.stop();
  }

  async _handleEnter() {
    console.log(
      "ChannelItem _handleEnter",
      this.channel
    );

    if (
      this.channel.protected_channel ===
      "1"
    ) {
      var pinConfigured = Storage.get(
        "pin_configured"
      );
      if (!pinConfigured) {
        //todo alert to configure pin
        return;
      }

      Storage.set(
        "channel",
        this.channel
      );
      Router.focusWidget("PinConfig");
      return;
    }

    Router.navigate("channel", {
      channel: this.channel,
    });
    Router.focusPage();
  }

  static get marginRight() {
    return 0;
  }

  static get marginBottom() {
    return 0;
  }

  static get marginLeft() {
    return 0;
  }

  static get marginTop() {
    return 0;
  }

  static get height() {
    return widthBox;
  }

  static get width() {
    return widthBox;
  }
}
