import { Storage } from "@lightningjs/sdk";
import { axiosClient } from "../Client";

export function token(username, password) {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('client_id', 'MdO6QONxC_fI0xvbRq7wLZxa6Q1cDYHh7xPkYcdLy_Q');
  params.append('client_secret', 'cliente_simple_oauth2023OKTV');
  params.append('username', username);
  params.append('password', password);
  return axiosClient.post("oauth/token", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
export function refreshToken(refreshToken) {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('client_id', 'MdO6QONxC_fI0xvbRq7wLZxa6Q1cDYHh7xPkYcdLy_Q');
  params.append('client_secret', 'cliente_simple_oauth2023OKTV');
  params.append('refresh_token', refreshToken);
  return axiosClient.post("oauth/token?refresh=true", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}