"use strict";
import { LoginPage } from "../Pages/LoginPage.js";
import { HomePage } from "../Pages/HomePage.js";
import { BootPage } from "../Pages/_defaults/BootPage.js";
import { NotFoundPage } from "../Pages/_defaults/NotFoundPage.js";
import { ErrorPage } from "../Pages/_defaults/ErrorPage.js";
import { ChannelPlayer } from "../Pages/ChannelPlayer.js";
import { Img, Storage } from "@lightningjs/sdk";
import {
  getChannels,
  getFavoritesChannel,
} from "../Services/Api/Routes/Channels";
import { getOperators, getBanners } from "../Services/Api/Routes/User";
import { getBasicConfig } from "../Services/Api/Routes/Config.js";
import { groupBy } from "./Common.js";
import { LangSelectorPage } from "../Pages/LangSelectorPage.js";

export default [
  {
    path: "$",
    options: {
      preventStorage: true,
      clearHistory: true,
    },
    component: BootPage,
  },
  {
    path: "*",
    options: {
      preventStorage: true,
      clearHistory: true,
    },
    component: NotFoundPage,
  },
  {
    path: "!",
    options: {
      preventStorage: true,
      clearHistory: true,
    },
    component: ErrorPage,
  },
  {
    path: "home",
    widgets: ['PinConfig'],
    component: HomePage,
    options: { clearHistory: true },
    before: async (page) => {
      let auth = Storage.get("token");
      if (!auth) {
        return;
      }

      await page.initialize();
    },
  },
  {
    path: "login",
    widgets: [],
    options: {
      preventStorage: true,
      clearHistory: true,
    },
    component: LoginPage,
    before: async (page) => {
      const config = await getBasicConfig();
      if (config.data && config.data.length > 0) {
        Storage.set("basic_config", config.data)
        var loginBgData = config.data.find((x) => x.key === "login_bg");
        if (loginBgData) {
          page.patch({
            Background: Img(loginBgData.value).landscape(1920),
          });
        }
      }
    },
  },
  {
    path: "langSelector",
    widgets: [],
    options: {
      preventStorage: true,
      clearHistory: true,
    },
    component: LangSelectorPage,
    before: async (page) => {
      const config = await getBasicConfig();
      if (config.data && config.data.length > 0) {
        Storage.set("basic_config", config.data)
        var loginBgData = config.data.find((x) => x.key === "login_bg");
        if (loginBgData) {
          page.patch({
            Background: Img(loginBgData.value).landscape(1920),
          });
        }
      }
    },
  },
  {
    path: "channel",
    widgets: [],
    options: {
      preventStorage: true,
      clearHistory: false,
    },
    component: ChannelPlayer,
    before: async (page, { channel }) => {
      page._channel = channel;
      if (channel !== undefined) {
        page.tag(
          "TitleChannel"
        ).text.text = `${channel.posicion} ${channel.title}`;

        let operator = Storage.get("current_operator");
        const userId = Storage.get("current_user_id");
        if (!operator && userId) {
          const operatorsResponse = await getOperators(userId);
          if (operatorsResponse.status === 200) {
            Storage.set("current_operator", operatorsResponse.data[0]);
            operator = operatorsResponse.data[0];
          }
        }

        if (operator && !channel.field_canal_id) {
          const allChannels = await getChannels(operator.operador);
          page._channels = allChannels.data;
        } else if (channel.field_canal_id && userId) {
          const allChannels = await getFavoritesChannel(
            userId,
            operator.operador
          );
          page._channels = allChannels.data;
        }
      }
    },
  },
];
