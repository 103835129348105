import { Colors, Img, Lightning, Router, Utils } from "@lightningjs/sdk";
import { Grid } from "@lightningjs/ui";
import EPGItem from "./EPGItem";
import { focusedColor, primaryColor } from "../../Lib/Common";

const baseImageUrl =
  process.env.APP_BACKEND_URL || `https://devportal.ok-television.com`;
export default class EPGRow extends Lightning.Component {
  static _template() {
    return {
      rect: false,
      w: 1600,
      h: 100,
      ChannelWrapper: {
        zIndex: 2,
        rect: true,
        w: 500,
        h: 100,
        color: Colors("#0A0A0A").get(),
        mount: 0,
        Index: {
          x: 10,
          y: 25,
          w: 70,
          h: 100,
          text: {
            text: "",
          },
        },
        Logo: {
          x: 90,
          mountY: 0,
          w: 100,
          h: 100,
          src: Utils.asset("images/logo.png"),
        },
        Title: {
          y: 25,
          x: 205,
          w: 290,
          h: 100,
          text: {
            text: "",
            maxLines: 1,
            wordWrapWidth: 290,
            wordWrap: false,
            textOverflow: "ellipsis",
          },
        },
      },
      Programs: {
        zIndex: 0,
        y: 0,
        x: 510,
        w: 800,
        type: Grid,
        itemType: EPGItem,
        forceLoad: false,
        spacing: 10,
        direction: "row",
      },
    };
  }

  _firstActive() {
    if (this._readyInit) return;
    if (this.epgChannel.channel.abreviacion) {
      this.tag("Title").text.text = this.epgChannel.channel.abreviacion;
    } else {
      this.tag("Title").text.text =
        this.epgChannel.children[0].children[0].value;
    }
    if (this.epgChannel.channel.field_logo) {
      const url = baseImageUrl + this.epgChannel.channel.field_logo;
      this.patch({
        ChannelWrapper: { Logo: Img(url).contain(100, 100) },
      });
    }
    var str = "" + this.epgChannel.channel.posicion;
    var pad = "000";
    var ans = pad.substring(0, pad.length - str.length) + str;
    this.tag("Index").text.text = ans;
    this.ref = `EPGID_${this.epgChannel.attributes.id}`;
    if (this.epgChannel.programs && this.epgChannel.programs.length > 0)
      this.tag("Programs").reload(this.epgChannel.programs);

    this._readyInit = true;
  }

  _focus() {
    this.tag("Title").setSmooth("color", primaryColor);
  }

  _unfocus() {
    this.tag("Title").setSmooth("color", Colors("white").alpha(1).get());
  }

  _getFocused() {
    return this.tag("Programs");
  }

  _handleEnter() {
    if (this.epgChannel.channel.field_url) {
      Router.navigate("channel", {
        channel: this.epgChannel.channel,
      });
      Router.focusPage();
    }
  }

  static get marginRight() {
    return 0;
  }

  static get marginBottom() {
    return 0;
  }

  static get marginLeft() {
    return 0;
  }

  static get marginTop() {
    return 0;
  }

  static get height() {
    return 100;
  }

  static get width() {
    return 1600;
  }
}
