import { Colors } from "@lightningjs/sdk";
import jwt_decode from "jwt-decode";
// import { toJson } from "xml2json";

export const bgColor = Colors("#000000").get();
export const primaryColor = Colors("#5271FF").get();
export const focusedColor = Colors("#FDDE59").get();
export const focusedColorWithTransparency = (transparency) => Colors("#FDDE59").alpha(transparency).get();

export const validJwt = (token) => {
  var decoded = jwt_decode(token);
  if (decoded.exp * 1000 < new Date().getTime()) {
    return false;
  }

  return true;
};
export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};