import { Colors, Lightning, Utils } from "@lightningjs/sdk";

export const successColor = Colors("#6dc066").get();
export const errorColor = Colors("#bb2124").get();
export const warningColor = Colors("#f0ad4e").get();
export const infoColor = Colors("#5bc0de").get();
export const defaultColor = Colors("#aaaaaa").get();
export const accentColor = Colors("#FDDE59").get();

export class Alert extends Lightning.Component {
  static _template() {
    return {
      alpha: 0,
      x: 480,
      y: h => h/2,
      Wrapper: {
        rect: true,
        color: accentColor,
        w: 960,
        h: 140,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 10,
          stroke: 0,
          strokeColor: accentColor,
          blend: 1,
          fillColor: accentColor,
        },
        Alert: {
          w: 900,
          h: 140,
          text: {
            text: "",
            fontSize: 36,
            lineHeight: 40,
            highlight: false,
            highlightColor: accentColor,
            textAlign: "center",
            verticalAlign: "middle",
            fontFace: "Regular", 
            highlightPaddingLeft: 10,
            highlightPaddingRight: 10,
            wordWrapWidth: 900,
            paddingLeft: 10,
            paddingRight: 10,
            maxLines: 3,
            textColor: Colors("#000000").get(),
          },
        },
      },
    };
  }

  set message(v) {
    if (v) {
      this.tag("Alert").text.text = v;
    }
  }
  set type(v) {
    if (v) {
      this.tag("Wrapper").color = accentColor;
    }
  }

  showAlert(milliseconds) {
    this.patch({
      smooth: {
        alpha: [1, { duration: 1, delay: 1, timingFunction: "ease-out" }],
      },
    });
    setTimeout(() => {
      this.patch({
        smooth: {
          alpha: [0, { duration: 2.5, delay: 1, timingFunction: "ease-out" }],
        },
      });
    }, milliseconds);
  }
}
