import { Log, Router, Storage } from "@lightningjs/sdk";
import axios from "axios";
import { validJwt } from "../../Lib/Common";
import { refreshToken } from "./Routes/Auth";
const axiosClient = axios.create({
  baseURL: process.env.APP_BACKEND_URL || `https://devportal.ok-television.com`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(async function (request) {
  const token = Storage.get("token");
  if(request.url.includes("refresh=true")){
    return request;
  }

  if (token && token.access_token && validJwt(token.access_token)) {
    request.headers.set("Authorization", `Bearer ${token.access_token}`);
  } else if (token && token.access_token && !validJwt(token.access_token)) {
    const tokenResponse = await refreshToken(token.refresh_token);
    if (tokenResponse && tokenResponse.status === 200) {
      Storage.set("token", tokenResponse.data);
      request.headers.set("Authorization", `Bearer ${tokenResponse.data.access_token}`);
    } else {
      Storage.clear();
      Router.root();
    }
  }

  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error(error);
    if (error.response?.status === 401) {
      Storage.clear();
      Router.root();
      return;
    }
    return Promise.reject(error);
  }
);

export { axiosClient };
