import { Colors, Lightning, Log } from "@lightningjs/sdk";
import { focusedColor } from "../../Lib/Common";

export default class MenuItem extends Lightning.Component {
  static _template() {
    return {
      h: 100,
      alpha: 1,
      Label: {
        mountY: 0.5,
        y: 50,
        text: { fontFace: "Regular", fontSize: 42 },
      },
    };
  }

  set label(v) {
    this.tag("Label").text = v;
  }

  set pageId(v) {
    this._pageId = v;
  }

  get pageId() {
    return this._pageId;
  }

  _init() {
    this._focusAnimation = this.animation({
      duration: 0.3,
      actions: [
        {
          t: "Label",
          p: "text.textColor",
          v: {
            0: Colors('white').alpha(1).get(),
            1: focusedColor,
          },
        },
      ],
    });
  }

  activeStatus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _handleEnter() {
    if (this.action) {
      this.action && this.fireAncestors(this.action);
    }
  }

  _focus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _unfocus() {
    this._focusAnimation.stop();
  }
  
}
