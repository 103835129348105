import { Colors, Lightning } from "@lightningjs/sdk";
import { primaryColor } from "../../Lib/Common";
import moment from "moment/moment";

export default class EPGItem extends Lightning.Component {
  static _template() {
    return {
      ShadowTime: {
        rect: true,
        w: 0,
        alpha: 0,
        h: (h) => h,
        mountY: 0,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          blend: 1,
          fillColor: Colors("#5271FF").alpha(0.8).get(),
        },
      },
      Focus: {
        rect: true,
        w: (w) => w,
        h: (h) => h,
        mountY: 0,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 0,
          strokeColor: primaryColor,
          blend: 1,
          fillColor: Colors("#0A0A0A").alpha(0.8).get(),
        },
        Line: {
          rect: true,
          color: Colors("#ffffff").get(),
          w: 0,
          h: 50,
        },
      },
      Content: {
        rect: true,
        w: (w) => w,
        h: (h) => h - 12,
        mountY: 0,
        color: Colors("#0A0A0A").alpha(0.8).get(),
        Title: {
          y: 20,
          x: 10,
          text: {
            text: "Test",
            fontSize: 28,
            maxLines: 1,
            wordWrapWidth: 380,
            wordWrap: false,
            textOverflow: "ellipsis",
          },
        },
        HourRange: {
          y: 50,
          x: 10,
          text: {
            text: "16:00 - 16:30",
            fontSize: 24,
            maxLines: 1,
            wordWrapWidth: 380,
            wordWrap: false,
            textOverflow: "ellipsis",
          },
        },
      },
    };
  }

  _init() {
    this._focusAnimation = this.animation({
      duration: 0.3,
      actions: [
        {
          t: "Focus",
          p: "shader.fillColor",
          v: {
            0: Colors("#0A0A0A").alpha(0.8).get(),
            1: primaryColor,
          },
        },
      ],
    });
  }

  _active() {
    if (this._readyInit) return;
    this.tag("Title").text.text = this.programmer.children[0].children[0].value;
    const startDate = moment(
      this.programmer.attributes.start,
      "YYYYMMDDhhmmss Z"
    );
    const endDate = moment(this.programmer.attributes.stop, "YYYYMMDDhhmmss Z");
    this.tag("HourRange").text.text = `${startDate.format(
      "ddd DD/MM"
    )} ${startDate.format("LT")} - ${endDate.format("LT")}`;
    const currentDate = moment();
    this._duration = moment.duration(endDate.diff(startDate)).asHours();
    this._currentDuration = moment.duration(currentDate.diff(startDate)).asHours();

    if (this._currentDuration > 0) {
      const hours = this._currentDuration;
      var width = (400 * hours) / this._duration;
      if (width > 0 && width < 400) {
        this.patch({
          ShadowTime: { alpha: 1, w: width },
        });
      }
    }

    this._readyInit = true;
  }

  _focus() {
    if (this._focusAnimation) {
      this._focusAnimation.start();
    }
  }

  _unfocus() {
    this._focusAnimation.stop();
  }

  static get height() {
    return 100;
  }
  static get width() {
    return 400;
  }
}
