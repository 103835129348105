import { Router, Storage, Utils } from "@lightningjs/sdk";
import Routes from "./Lib/Routes.js";
import { getOS } from "./Lib/Security.js";
import { configureLang } from "./Services/Api/Routes/Config.js";
import PinConfig from "./Widgets/PinConfig.js";
export default class App extends Router.App {
  static getFonts() {
    return [
      { family: "Regular", url: Utils.asset("fonts/Ubuntu-Regular.ttf") },
    ];
  }

  static _template() {
    return {
      ...super._template(),
      Pages: {
        w: 1920,
        h: 1080,
        collision: true,
        forceZIndexContext: true,
      },
      Widgets: {
        PinConfig: { type: PinConfig },
      },
    };
  }

  _handleAppClose() {
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      webOS.platformBack();
    }else if(sistemaOperativo === "Samsung TV"){
      window.tizen.application.getCurrentApplication().exit();
    }
    else{
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  async _setup() {
    await configureLang();
    const routeConfig = {
      root: () => {
        return new Promise((resolve) => {
          const token = Storage.get("token");
          const langConfigure = Storage.get("selectedLanguage");
          console.log("langConfigure", langConfigure)
          if(!langConfigure){
            resolve("langSelector");
          }else if (token) {
            resolve("home");
          } else {
            resolve("login");
          }
        });
      },
      routes: Routes,
    };
    Router.startRouter(routeConfig, this);
  }
}
