import {
  Keyboard,
  Key as BaseKey,
  InputField,
} from "@lightningjs/ui";
import {
  Lightning,
  Colors,
  Utils,
  Router,
  Storage,
  Img,
  Language,
} from "@lightningjs/sdk";
import Button from "../Components/Button";
import { token } from "../Services/Api/Routes/Auth";
import {
  Alert,
  errorColor,
  infoColor,
} from "../Components/Alert";
import { getOperators } from "../Services/Api/Routes/User";
import jwt_decode from "jwt-decode";
import LoadingIndicator from "../Components/LoadingIndicator";
import {
  getOS,
  verifyDevices,
} from "../Lib/Security";
export class LoginPage extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Background: {
        rect: true,
        w: 1920,
        h: 1080,
      },
      Logo: {
        mount: 0.5,
        alpha: 0,
        w: 140,
        h: 140,
        x: 520,
        y: 120,
        src: Utils.asset(
          "images/logo.png"
        ),
      },
      Text: {
        mount: 0.5,
        x: 960,
        y: 120,
        text: {
          text: Language.translate(
            "log_in_title"
          ),
          shadow: true,
          fontSize: 54,
          textColor:
            Colors("#ffffff").get(),
        },
      },
      Alert: {
        type: Alert,
        zIndex: 9999,
      },
      UsernameInputFieldWrapper: {
        collision: true,
        x: 480,
        y: 200,
        rect: true,
        h: 90,
        w: 960,
        color: Colors("#ffffff").get(),
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        UsernameInputField: {
          x: 20,
          y: 20,
          type: InputField,
          description:
            Language.translate(
              "log_in_user_placeholder"
            ),
          autoHideCursor: false,
          inputText: {
            paddingLeft: 20,
            textColor:
              Colors("#121212").get(),
          },
        },
      },
      PasswordInputFieldWrapper: {
        collision: true,
        x: 480,
        y: 300,
        rect: true,
        h: 90,
        w: 960,
        color: Colors("#ffffff").get(),
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        PasswordInputField: {
          x: 20,
          y: 20,
          type: InputField,
          description:
            Language.translate(
              "log_in_password_placeholder"
            ),
          passwordMode: true,
          autoHideCursor: true,
          inputText: {
            paddingLeft: 20,
            textColor:
              Colors("#121212").get(),
          },
        },
      },
      LoginButton: {
        collision: true,
        y: 420,
        x: 480,
        w: 960,
        type: Button,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        ref: "LoginButton",
        action: "$login",
        label: Language.translate(
          "log_in_button"
        ),
      },
      ExitButton: {
        collision: true,
        y: 530,
        x: 480,
        w: 960,
        type: Button,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        ref: "ExitButton",
        action: "$exitApp",
        label: Language.translate(
          "log_in_exit_button"
        ),
      },
      LoadingIndicator: {
        type: LoadingIndicator,
        x: 540,
        width: 840,
        y: 885,
      },
      KeyboardWrapper: {
        alpha: 1,
        mountX: 0.5,
        x: 960,
        y: 800,
        rect: true,
        shader: {
          type: Lightning.shaders
            .RoundedRectangle,
          radius: 20,
        },
        h: 280,
        w: 860,
        color: Colors("#1D2228").get(),
        MyKeyboard: {
          y: 10,
          w: 860,
          h: 300,
          type: Keyboard,
          alpha: 1,
          config: myKeyboardConfig,
          collision: true,
          currentLayout: "a",
          maxCharacters: 56,
          signals: { onDone: true },
        },
      },
    };
  }
  onDone(event) {
    const inputCurrent = this.tag(
      "MyKeyboard"
    ).currentInputField;
    if (
      inputCurrent &&
      inputCurrent.ref ===
        "UsernameInputField"
    ) {
      this.focusedPassword();
    } else if (
      inputCurrent &&
      inputCurrent.ref ===
        "PasswordInputField"
    ) {
      this.focusLoginButton();
    } else {
      this.focusedUsername();
    }
  }

  async $login() {
    try {
      let user = this.tag(
        "UsernameInputField"
      ).input;
      let pass = this.tag(
        "PasswordInputField"
      ).input;
      this.tag(
        "LoadingIndicator"
      ).show();
      const tokenResponse = await token(
        user,
        pass
      );

      if (
        tokenResponse.status === 200
      ) {
        if (
          tokenResponse &&
          tokenResponse.data &&
          tokenResponse.data
            .access_token
        ) {
          var decoded = jwt_decode(
            tokenResponse.data
              .access_token
          );
          Storage.set(
            "current_user_id",
            decoded.sub
          );
          Storage.set(
            "token",
            tokenResponse.data
          );
          const operatorsResponse =
            await getOperators(
              decoded.sub
            );

          if (
            operatorsResponse.status ===
            200
          ) {
            const verify =
              await verifyDevices(
                operatorsResponse.data,
                decoded.sub,
                this
              );
            if (verify) {
              Router.root();
            }
          }
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data
      ) {
        this.tag("Alert").message =
          error.response.data.message;
      } else {
        this.tag("Alert").message =
          error.message;
      }

      this.tag("Alert").type =
        errorColor;
      this.tag("Alert").showAlert(3000);
    }

    this.tag("LoadingIndicator").hide();
  }
  _active() {
    super._active();

    this.focusKeyboard();
  }
  async _init() {
    this.sistemaOperativo = getOS();
    this.patch({
      Background: Img(
        "images/tv_login_bg3.png"
      ).landscape(1920),
    });
    this.refComponentFocus =
      "MyKeyboard";
  }

  _handleUp() {
    if (
      this.refComponentFocus ==
      "LoginButton"
    ) {
      this.focusKeyboard();
    } else if (
      this.refComponentFocus ===
      "ExitButton"
    ) {
      this.focusLoginButton();
    }
  }
  _handleDown() {
    if (
      this.refComponentFocus ==
      "ExitButton"
    ) {
      this.focusKeyboard();
    } else if (
      this.refComponentFocus ==
      "LoginButton"
    ) {
      this.focusExitButton();
    }
  }
  _handleEnter() {
    if (
      this.refComponentFocus ==
      "MyKeyboard"
    ) {
      this.onDone(null);
    }
  }
  focusKeyboard() {
    this.refComponentFocus =
      "MyKeyboard";
    this.patch({
      KeyboardWrapper: {
        smooth: {
          alpha: 1,
        },
      },
    });
    this.focusedUsername();
  }

  focusLoginButton() {
    this.tag(
      "MyKeyboard"
    ).currentInputField = null;
    this.tag(
      "UsernameInputField"
    ).toggleCursor(false);
    this.tag(
      "UsernameInputFieldWrapper"
    ).color = Colors("#ffffff").get();
    this.tag(
      "PasswordInputField"
    ).toggleCursor(false);
    this.tag(
      "PasswordInputFieldWrapper"
    ).color = Colors("#ffffff").get();
    this.refComponentFocus =
      "LoginButton";
    this.patch({
      KeyboardWrapper: {
        smooth: {
          alpha: 0,
        },
      },
    });
  }

  focusExitButton() {
    this.tag(
      "MyKeyboard"
    ).currentInputField = null;
    this.tag(
      "UsernameInputField"
    ).toggleCursor(false);
    this.tag(
      "UsernameInputFieldWrapper"
    ).color = Colors("#ffffff").get();
    this.tag(
      "PasswordInputField"
    ).toggleCursor(false);
    this.tag(
      "PasswordInputFieldWrapper"
    ).color = Colors("#ffffff").get();
    this.refComponentFocus =
      "ExitButton";
    this.patch({
      KeyboardWrapper: {
        smooth: {
          alpha: 0,
        },
      },
    });
  }

  _getFocused() {
    return this.tag(
      this.refComponentFocus
    );
  }

  focusedPassword() {
    this.tag("MyKeyboard").inputField(
      this.tag("PasswordInputField")
    );

    this.tag(
      "PasswordInputField"
    ).toggleCursor(true);
    this.tag(
      "UsernameInputField"
    ).toggleCursor(false);
    this.tag(
      "UsernameInputFieldWrapper"
    ).color = Colors("#ffffff").get();

    this.tag(
      "PasswordInputFieldWrapper"
    ).color = Colors("#eeeeee").get();
  }
  focusedUsername() {
    this.tag("MyKeyboard").inputField(
      this.tag("UsernameInputField")
    );

    this.tag(
      "UsernameInputField"
    ).toggleCursor(true);

    this.tag(
      "PasswordInputField"
    ).toggleCursor(false);

    this.tag(
      "PasswordInputFieldWrapper"
    ).color = Colors("#ffffff").get();

    this.tag(
      "UsernameInputFieldWrapper"
    ).color = Colors("#eeeeee").get();
  }
  $exitApp() {
    const sistemaOperativo = getOS();
    if (
      sistemaOperativo === "LG/SmartTV"
    ) {
      webOS.platformBack();
    } else if (
      sistemaOperativo === "Samsung TV"
    ) {
      window.tizen.application
        .getCurrentApplication()
        .exit();
    } else {
      this.application.closeApp();
      window.location.href =
        "about:blank";
    }
  }
  _captureKey(key) {
    if (
      this.sistemaOperativo ===
        "Samsung TV" &&
      key.keyCode === 10009
    ) {
      this._handleBack();
      return true;
    }
    return false;
  }
  _handleBack() {
    if (
      this.refComponentFocus ===
      "MyKeyboard"
    ) {
      this.focusLoginButton();
    } else {
      const sistemaOperativo = getOS();
      if (
        sistemaOperativo ===
        "LG/SmartTV"
      ) {
        webOS.platformBack();
      } else if (
        sistemaOperativo ===
        "Samsung TV"
      ) {
        window.tizen.application
          .getCurrentApplication()
          .exit();
      } else {
        this.application.closeApp();
        window.location.href =
          "about:blank";
      }
    }
  }
  _handleClick(target, coords) {
    if (target.ref === "MyKeyboard") {
      this.tag("Alert").message =
        Language.translate(
          "log_in_keysmap_error"
        );
      this.tag("Alert").type =
        infoColor;
      this.tag("Alert").showAlert(5000);
    } else if (
      target.ref ===
      "UsernameInputFieldWrapper"
    ) {
      this.refComponentFocus =
        "MyKeyboard";
      this.patch({
        KeyboardWrapper: {
          smooth: {
            alpha: 1,
          },
        },
      });
      this.focusedUsername();
    } else if (
      target.ref ===
      "PasswordInputFieldWrapper"
    ) {
      this.refComponentFocus =
        "MyKeyboard";
      this.patch({
        KeyboardWrapper: {
          smooth: {
            alpha: 1,
          },
        },
      });
      this.focusedPassword();
    } else if (
      target.ref === "LoginButton"
    ) {
      this.focusLoginButton();
      this.tag(
        "LoginButton"
      )._handleEnter();
    } else if (
      target.ref === "ExitButton"
    ) {
      this.focusExitButton();
      this.tag(
        "ExitButton"
      )._handleEnter();
    }
  }
}
// #region Keys config
class Key extends BaseKey {
  static _template() {
    return {
      ...super._template(),
      rect: true,
      collision: true,
    };
  }
  _firstActive() {
    this.label = {
      mountY: 0.45,
      text: {
        fontSize: 40,
      },
    };
    this.labelColors = {
      unfocused: Colors("white").get(),
      focused: Colors("#3C4043").get(),
    };

    this.backgroundColors = {
      unfocused:
        Colors("#2A3139").get(),
      focused: Colors("white").get(),
    };
    if (this.hasFocus()) {
      this._focus();
    }
  }
  static get height() {
    return 60;
  }
  static get width() {
    return 60;
  }
}

class ActionKey extends BaseKey {
  _active() {
    this.label = {
      mountY: 0.5,
      mountX: 0.5,
      text: {
        fontSize: 40,
      },
    };
    this.labelColors = {
      unfocused: Colors("white").get(),
      focused: Colors("#3C4043").get(),
    };
    this.backgroundColors = {
      unfocused:
        Colors("#2A3139").get(),
      focused: Colors("white").get(),
    };
    if (this.hasFocus()) {
      this._focus();
    }
  }

  static get height() {
    return 60;
  }

  static get width() {
    return 100;
  }
}

class IconKey extends ActionKey {
  set icon(src) {
    this._icon = src;
    //call for _update
    this._update();
  }

  get icon() {
    return this._icon;
  }

  _update() {
    if (!this.active) {
      //blocking update if not active.
      return;
    }
    const hasFocus = this.hasFocus();
    let {
      focused,
      unfocused = 0xff000000,
    } = this.backgroundColors;
    //Use labelColors and label to color the icon.
    let {
      focused: labelFocused,
      unfocused:
        labelUnfocused = 0xffffffff,
    } = this.labelColors;
    this.patch({
      Background: {
        color:
          hasFocus && focused
            ? focused
            : unfocused,
      },
      Label: {
        src: Utils.asset(
          hasFocus && labelFocused
            ? `${this.icon}_unfocused.png`
            : `${this.icon}_focused.png`
        ),
        color:
          hasFocus && labelFocused
            ? labelFocused
            : labelUnfocused,
        w: 40,
        h: 40,
      },
    });
  }
}

const myKeyboardConfig = {
  layouts: {
    a: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Clear",
      ],
      [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "Backspace",
      ],
      [
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "Done",
      ],
      [
        "Layout:A",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
        ",",
        ".",
        "-",
      ],
    ],
    A: [
      [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Clear",
      ],
      [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "Backspace",
      ],
      [
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "Done",
      ],
      [
        "Layout:a",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        ";",
        ":",
        "_",
      ],
    ],
  },
  styling: {
    align: "center",
    horizontalSpacing: 5,
    verticalSpacing: 5,
  },
  buttonTypes: {
    default: {
      type: Key,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
    },
    Backspace: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/backspace",
      w: 220,
    },
    Layout: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/format-letter-case-upper",
    },
    Clear: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/close-circle-outline",
      w: 160,
    },
    Done: {
      type: IconKey,
      shader: {
        type: Lightning.shaders
          .RoundedRectangle,
        radius: 10,
      },
      icon: "images/icons/check-circle-outline",
    },
  },
};
// #endregion
