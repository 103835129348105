import { Language, Lightning, Router, Storage } from "@lightningjs/sdk";
import Menu from "../Components/Menu/Menu";
import Banners from "../Components/Banner/Banners";
import ChannelsControl from "../Components/Channels/ChannelsControl";
import { bgColor } from "../Lib/Common";
import { Grid } from "@lightningjs/ui";
import EPGMain from "../Components/EPG/EPGMain";
import { SettingsTile } from "../Components/SettingsTile";
import LoadingIndicator from "../Components/LoadingIndicator";
import {
  getOperators,
  getBanners,
  getDevices,
} from "../Services/Api/Routes/User";
import { getBasicConfig, getLangConfig, getPlayerConfig, getPlayerConfigByOperator } from "../Services/Api/Routes/Config.js";
import { groupBy } from "../Lib/Common";
import {
  getChannels,
  getFavoritesChannel,
} from "../Services/Api/Routes/Channels";
import { Alert, infoColor } from "../Components/Alert.js";
import {
  getOS,
  removeAllDevices,
  verifyDeviceInExistingSessions,
} from "../Lib/Security.js";

const UIIndex = {
  Menu: 1,
  Banners: 0,
  Categories: 2,
  Favs: 3,
  EPG: 4,
  Settings: 5,
  Exit: 6,
  UnfocusedElements: [0, 6],
};

export class HomePage extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Alert: {
        type: Alert,
        zIndex: 9999,
      },
      Background: {
        rect: true,
        w: 1920,
        h: 1080,
        color: bgColor,
      },
      LoadingIndicator: {
        type: LoadingIndicator,
        y: 400,
        x: 1000,
        width: 900,
      },
      UI: {
        Banners: {
          type: Banners,
          zIndex: 999,
        },
        Menu: {
          type: Menu,
          zIndex: 9999,
          y: 350,
          change: "$menuChanged",
          focus: "$requestFocus",
        },
        Categories: {
          zIndex: 2,
          alpha: 1,
          x: 320,
          y: 400,
          w: 1600,
          h: 400,
          type: Grid,
          itemType: ChannelsControl,
          spacing: 20,
          direction: "column",
          collision: true,
        },
        Favs: {
          zIndex: 2,
          alpha: 0,
          x: 320,
          y: 400,
          w: 1600,
          h: 400,
          type: Grid,
          itemType: ChannelsControl,
          spacing: 20,
          direction: "column",
          collision: true,
        },
        EPG: {
          zIndex: 2,
          alpha: 0,
          x: 320,
          y: 380,
          w: 1600,
          h: 420,
          type: EPGMain,
        },
        Settings: {
          zIndex: 2,
          alpha: 0,
          x: 320,
          y: 400,
          w: 1400,
          h: 420,
          type: SettingsTile,
          focus: "$requestFocus",
          signals: {
            canChangeDevice: true,
          },
        },
        Exit: {
          zIndex: 2,
          alpha: 0,
          x: 400,
          y: 680,
          mount: 0,
          w: 1500,
          h: 220,
          text: {
            text: Language.translate("home_go_to_login"),
          },
        },
      },
    };
  }

  _init() {
    this.index = UIIndex.Menu;
  }

  async _active() {
    this.loadFavs();
    var config = await getPlayerConfig();
    Storage.set("playerConfig", config.data);
    const operator = Storage.get("current_operator");
    var configByOperator = await getPlayerConfigByOperator(operator.operador);
    Storage.set("playerConfigOperator", configByOperator.data);

  }
  _handleLeft() {
    if (
      this.index === UIIndex.Banners ||
      this.index === UIIndex.Categories ||
      this.index === UIIndex.Favs ||
      this.index === UIIndex.EPG ||
      this.index === UIIndex.Settings
    )
      this.index = UIIndex.Menu;
  }
  _handleAppClose() {
    const sistemaOperativo = getOS();
    if (sistemaOperativo === "LG/SmartTV") {
      webOS.platformBack();
    } else if (sistemaOperativo === "Samsung TV") {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _handleBack() {
    const sistemaOperativo = getOS();
    if (sistemaOperativo === "LG/SmartTV") {
      webOS.platformBack();
    } else if (sistemaOperativo === "Samsung TV") {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _handleRight() {
    if (this.index === UIIndex.Menu) {
      const activeItem = this.tag("UI.Menu").activeItem;
      if (activeItem.pageId === "home") {
        this.index = UIIndex.Categories;
      } else if (activeItem.pageId === "favorites") {
        this.index = UIIndex.Favs;
      } else if (activeItem.pageId === "epg") {
        this.index = UIIndex.EPG;
      } else if (activeItem.pageId === "settings") {
        this.index = UIIndex.Settings;
      }
    }
  }
  async initialize(force = false) {
    if (this._isInit && !force) return;

    const userId = Storage.get("current_user_id");
    let operator = Storage.get("current_operator");

    const operatorsResponse = await getOperators(userId);

    const configResponse = await getBasicConfig();
    Storage.set("basic_config", configResponse.data);

    if (!operator || !operator.operador) {
      if (operatorsResponse && operatorsResponse.status === 200) {
        Storage.set("current_operator", operatorsResponse.data[0]);
        operator = operatorsResponse.data[0];
      }
    }

    if (operatorsResponse && operatorsResponse.status === 200) {
      const operadorItems = operatorsResponse.data.map((item) => ({
        operator: {
          name: item.nombre_operador,
          id: item.operador,
          max_devices: item.max_devices,
        },
      }));
      this.tag("Settings").operators = operadorItems;
      const langConfig = await getLangConfig();
      this.tag("Settings").lang = langConfig.data.map((item) => ({
        lang: { langCode: item.key, name: item.value, image: item.image },
      }));
    }

    if (operator) {
      const verify = await verifyDeviceInExistingSessions(
        operator,
        userId,
        this
      );
      if (!verify) {
        this.$logout();
        return;
      }
      const channelsResponse = await getChannels(operator.operador);
      const banners = await getBanners(operator.operador);
      const devices = await getDevices(userId, operator.operador);
      const bannersItems = banners.data.map((item) => ({
        field_imagen: item.field_imagen,
      }));
      const devicesItems = devices.data.map((item) => ({
        device: item,
      }));

      const channelItems = groupBy(
        channelsResponse.data,
        "field_channel_category"
      );
      let channelsCats = [];
      if (channelsResponse.data.length > 0) {
        channelsCats.push({
          category: {
            category: Language.translate("home_all_channels_title"),
            id_category: "ALL_CHANNELS",
            items: channelsResponse.data,
          },
        });
      }

      Object.keys(channelItems).forEach((key) => {
        channelsCats.push({
          category: {
            category: key,
            id_category: key,
            items: channelItems[key],
          },
        });
      });

      if (bannersItems.length > 0) {
        this.tag("Banners").items = bannersItems;
      }

      if (channelsCats.length > 0) {
        this.tag("Categories").reload(channelsCats);
      }
      if (devicesItems.length > 0) {
        this.tag("Settings").devices = devicesItems;
      }
    }
    this._isInit = true;
  }

  async loadFavs() {
    const userId = Storage.get("current_user_id");
    const operator = Storage.get("current_operator");
    const favorites = await getFavoritesChannel(userId, operator.operador);

    const favoritesItems = groupBy(favorites.data, "field_channel_category");

    let favCats = [];
    if (favorites.data.length > 0) {
      favCats.push({
        category: {
          category: Language.translate("home_all_channels_title"),
          id_category: "ALL_FAVS",
          items: favorites.data,
        },
      });
    }

    Object.keys(favoritesItems).forEach((key) => {
      favCats.push({
        category: {
          category: key,
          id_category: key,
          items: favoritesItems[key],
        },
      });
    });

    if (favCats.length > 0) {
      this.tag("Favs").reload(favCats);
    } else {
      this.tag("Favs").clear();
    }
  }
  _handleClick(target, c) {
    if (target.ref === "Categories") {
      this.$requestFocus("home");
    } else if (target.ref === "Favs") {
      this.$requestFocus("favorites");
    } else if (target.ref === "EPG") {
      this.$requestFocus("epg");
    }

    this.tag("Alert").message = Language.translate("home_nav_channels_alert");
    this.tag("Alert").type = infoColor;
    this.tag("Alert").showAlert(5000);
  }
  async canChangeDevice(operator) {
    this.tag("LoadingIndicator").show();
    const userId = Storage.get("current_user_id");
    const verify = await verifyDeviceInExistingSessions(operator, userId, this);
    this.tag("LoadingIndicator").hide();
    if (!verify) {
      return false;
    }
    return true;
  }
  async $refresh() {
    this.tag("LoadingIndicator").show();
    await this.initialize(true);
    this.loadFavs();
    this.tag("LoadingIndicator").hide();
  }
  async $openSetPin() {
    Router.focusWidget("PinConfig");
  }
  reset() {
    this.index = 0;
    this._refocus();
  }

  _getFocused() {
    return this.tag("UI").children[this.index];
  }

  async $logout() {
    await removeAllDevices();
    this.tag("LoadingIndicator").show();
    setTimeout(() => {
      this.tag("LoadingIndicator").hide();
      Storage.clear();
      Router.root();
    }, 3000);
  }
  $requestFocus(pageId) {
    if (pageId === "home") {
      this.index = UIIndex.Categories;
    } else if (pageId === "favorites") {
      this.index = UIIndex.Favs;
    } else if (pageId === "epg") {
      this.index = UIIndex.EPG;
    } else if (pageId === "settings") {
      this.index = UIIndex.Settings;
    } else if (pageId == "menu") {
      this.index = UIIndex.Menu;
    }
  }
  $menuChanged() {
    const activeItem = this.tag("UI.Menu").activeItem;
    if (activeItem.pageId === "home") {
      this.patch({
        UI: {
          Categories: {
            smooth: {
              alpha: [1, { duration: 1, delay: 1, timingFunction: "ease-out" }],
            },
          },
          Favs: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          EPG: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Settings: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Exit: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
        },
      });
    } else if (activeItem.pageId === "favorites") {
      this.patch({
        UI: {
          Categories: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Favs: {
            smooth: {
              alpha: [1, { duration: 1, delay: 1, timingFunction: "ease-out" }],
            },
          },
          EPG: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Settings: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Exit: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
        },
      });
    } else if (activeItem.pageId === "epg") {
      this.patch({
        UI: {
          Categories: {
            smooth: {
              alpha: [
                0,
                { duration: 0.2, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Favs: {
            smooth: {
              alpha: [
                0,
                { duration: 0.2, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          EPG: {
            smooth: {
              alpha: [
                1,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Settings: {
            smooth: {
              alpha: [
                0,
                { duration: 0.2, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Exit: {
            smooth: {
              alpha: [
                0,
                { duration: 0.2, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
        },
      });
    } else if (activeItem.pageId === "settings") {
      this.patch({
        UI: {
          Categories: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Favs: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          EPG: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Settings: {
            smooth: {
              alpha: [1, { duration: 1, delay: 1, timingFunction: "ease-out" }],
            },
          },
          Exit: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
        },
      });
    } else if (activeItem.pageId === "exit") {
      this.patch({
        UI: {
          Categories: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Favs: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          EPG: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Settings: {
            smooth: {
              alpha: [
                0,
                { duration: 0.5, delay: 1, timingFunction: "ease-out" },
              ],
            },
          },
          Exit: {
            smooth: {
              alpha: [1, { duration: 1, delay: 1, timingFunction: "ease-out" }],
            },
          },
        },
      });
    }
  }
}
