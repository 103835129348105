import { axiosClient } from "../Client";
import axios from "axios";
export function getBanners(operator) {
  return axiosClient.get(`json_app_oktv/banners/${operator}?_format=json`);
}
export function getOperators(uid) {
  return axiosClient.get(
    `json_app_oktv/operadores_del_usuario_conectado/${uid}?_format=json`
  );
}
export function getDevices(userId, operatorId){
      return axiosClient.get(`/api/dispositivos-por-usuarios/${userId}/${operatorId}?_format=json`);
}
export function saveDevice(data, operadorId) {
  const obj = {
    type: [{ target_id: "dispositivos_por_usuarios" }],
    title: [{ value: "Dispositivo del usuario" }],
    field_id_opera: [{ value: operadorId }],
    field_dispositivo_ip: [{ value: data.ip }],
    field_dispositivo_mac: [{value: data.mac }],
    field_dispositivo_nombre: [{value: data.name }],
    field_pais_dispositivos: [{value:data.pais_dispositivo }]
  };

  return axiosClient.post("/node?_format=json", JSON.stringify(obj));
}
export function removeDevice(nodeId) {
      return axiosClient.delete(`/node/${nodeId}?_format=json`);
}
export function getIp(){
  return {
    data:{
      geoplugin_request: "0.0.0.0",
      geoplugin_countryName: "Unknow"
    }
  }
  // try {
  //   const axiosClient2 = axios.create();
  //   return axiosClient2.get("http://www.geoplugin.net/json.gp");
  // } catch (error) {
  //   return {
  //     data:{
  //       geoplugin_request: "0.0.0.0",
  //       geoplugin_countryName: "Unknow"
  //     }
  //   }
  // }
}