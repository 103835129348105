export const mp4loader = (url, videoEl, options = {}) => {
  return new Promise((resolve) => {
    videoEl.setAttribute("src", url);
    videoEl.load();
    resolve()
  });
};

export const mp4Unloader = (videoEl) => {
  return new Promise((resolve) => {
    videoEl.removeAttribute("src");
    videoEl.load();
    resolve();
  });
};
