import {
  Lightning,
  Colors,
  Utils,
  Router,
  Storage,
  Img,
  Language,
} from "@lightningjs/sdk";
import Button from "../Components/Button";
import { Alert, errorColor } from "../Components/Alert";
import LoadingIndicator from "../Components/LoadingIndicator";
import { getOS } from "../Lib/Security";
import { ListItemPicker } from "@lightningjs/ui-components";
import { configureLang, getLangConfig } from "../Services/Api/Routes/Config";

export class LangSelectorPage extends Lightning.Component {
  static _template() {
    return {
      w: 1920,
      h: 1080,
      Background: {
        rect: true,
        w: 1920,
        h: 1080,
      },
      Logo: {
        mount: 0.5,
        alpha: 0,
        w: 140,
        h: 140,
        x: 520,
        y: 120,
        src: Utils.asset("images/logo.png"),
      },
      Text: {
        mount: 0.5,
        x: 960,
        y: 120,
        text: {
          text: "",
          shadow: true,
          fontSize: 54,
          textColor: Colors("#ffffff").get(),
        },
      },
      Alert: {
        type: Alert,
        zIndex: 9999,
      },
      LangWrapper: {
        x: 600,
        y: 200,
        w: 700,
        rect: true,
        color: Colors("#999999").get(),
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 8 },
        h: 120,
        ListItemPicker: {
          w: 700,
          mode: "unfocused",
          type: ListItemPicker,
          title: "",
          style: {
            descriptionTextStyle:{
              textColor: Colors("#000").get(),
              fontSize: 36,
            }
          }
        },
      },
      ContinueButton: {
        collision: true,
        y: 575,
        x: 480,
        w: 960,
        type: Button,
        shader: { type: Lightning.shaders.RoundedRectangle, radius: 20 },
        ref: "ContinueButton",
        action: "$continue",
        label: Language.translate("continue_button"),
      },
      LoadingIndicator: {
        type: LoadingIndicator,
        x: 540,
        width: 840,
        y: 700,
      },
    };
  }

  async $continue() {
    try {

      this.tag("LoadingIndicator").show();
      var langSelected = this.tag("ListItemPicker").options[this.tag("ListItemPicker").selectedIndex];
      var langIndex = this.langData.findIndex(
        (lang) => lang.value === langSelected
      );
      var langCode = this.langData[langIndex].key;
      const currentLang = Language.get();
      const langChanged = langCode !== currentLang;
      Storage.set("selectedLanguage", langCode);
      await configureLang();
      Router.root();
      if (langChanged) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.tag("Alert").message = error.response.data.message;
      } else {
        this.tag("Alert").message = error.message;
      }

      this.tag("Alert").type = errorColor;
      this.tag("Alert").showAlert(3000);
    }

    this.tag("LoadingIndicator").hide();
  }
  _active() {
    super._active();

    this.focusedLangPicker();
  }

  async _init() {
    const langConfig = await getLangConfig();
    this.langData = langConfig.data;
    this.tag("ListItemPicker").options = this.langData.map(
      (lang) => lang.value
    );
    this.patch({
      Background: Img("images/tv_login_bg3.png").landscape(1920),
    });
    this.refComponentFocus = "ListItemPicker";
  }

  _handleUp() {
    if (this.refComponentFocus === "ListItemPicker") {
      this.focusExitButton();
    } else if (this.refComponentFocus == "ContinueButton") {
      this.focusedLangPicker();
    } else if (this.refComponentFocus === "ExitButton") {
      this.focusContinueButton();
    }
  }
  _handleDown() {
    if (this.refComponentFocus === "ListItemPicker") {
      this.focusContinueButton();
    } else if (this.refComponentFocus == "ExitButton") {
      this.focusedLangPicker();
    } else if (this.refComponentFocus == "ContinueButton") {
      this.focusExitButton();
    }
  }

  focusContinueButton() {
    this.refComponentFocus = "ContinueButton";
  }

  focusExitButton() {
    this.refComponentFocus = "ExitButton";
  }

  _getFocused() {
    return this.tag(this.refComponentFocus);
  }

  focusedLangPicker() {
    this.refComponentFocus = "ListItemPicker";
  }
  $exitApp() {
    const sistemaOperativo = getOS();
    if (sistemaOperativo === "LG/SmartTV") {
      webOS.platformBack();
    } else if (sistemaOperativo === "Samsung TV") {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _captureKey(key) {
    if (this.sistemaOperativo === "Samsung TV" && key.keyCode === 10009) {
      this._handleBack();
      return true;
    }
    return false;
  }
  _handleBack() {
    const sistemaOperativo = getOS();
    if (sistemaOperativo === "LG/SmartTV") {
      webOS.platformBack();
    } else if (sistemaOperativo === "Samsung TV") {
      window.tizen.application.getCurrentApplication().exit();
    } else {
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _handleClick(target, coords) {
    if (target.ref === "ContinueButton") {
      this.focusContinueButton();
      this.tag("ContinueButton")._handleEnter();
    } else if (target.ref === "ExitButton") {
      this.focusExitButton();
      this.tag("ExitButton")._handleEnter();
    } else if (target.ref === "ListItemPicker") {
      this.focusedLangPicker();
    }
  }
}