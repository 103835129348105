import { Colors, Lightning, Router, Img, Log, Storage } from "@lightningjs/sdk";
import { Carousel } from "@lightningjs/ui";
import BannerItem from "./BannerItem";
export default class Banners extends Lightning.Component {
  static _template() {
    return {
      rect: true,
      w: 1920,
      h: 350,
      color: Colors("#000000").get(),
      MyCarousel: {
        type: Carousel,
        direction: "row",
        itemType: BannerItem,
        autoResize: true,
      },
    };
  }

  set items(items) {
    this.tag("MyCarousel").reload(items);
  }

  async _firstActive() {
    const config = Storage.get("basic_config")
    var timeTransition = config.find(
      (x) => x.key === "banners_transicion_tiempo"
    );
    
    setInterval(() => {
      if (this.active === true && this.tag("MyCarousel").length > 0) {
        this.tag("MyCarousel").right();
      }
    }, this.parseToPrimitive(timeTransition.value) ?? 5000);
  }

  _handleRight() {
    this.tag("MyCarousel").right();
  }

  parseToPrimitive(value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      try {
        return eval(value);
      } catch (e) {
        return value.toString();
      }
    }
  }
}
