import { Lightning, Utils, Router, Img } from "@lightningjs/sdk";
import { bgColor } from "../../Lib/Common";
import { getOS } from "../../Lib/Security";

export class BootPage extends Lightning.Component {
  static _template() {
    return {
      Background: {
        w: 1920,
        h: 1080,
        rect: true
      }
    };
  }

  _handleAppClose() {
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      webOS.platformBack();
    }else if(sistemaOperativo === "Samsung TV"){
      window.tizen.application.getCurrentApplication().exit();
    }else{
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _handleBack() {
    const sistemaOperativo = getOS();
    if(sistemaOperativo === "LG/SmartTV"){
      webOS.platformBack();
    }else if(sistemaOperativo === "Samsung TV"){
      window.tizen.application.getCurrentApplication().exit();
    }else{
      this.application.closeApp();
      window.location.href = "about:blank";
    }
  }
  _init() {
    this.patch({
      Background: Img("images/tv_login_bg3.png").landscape(1920),
    });
  }
  _active(){
    setTimeout(() => {
      Router.resume();
    }, 2000);
  }
}
